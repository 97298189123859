var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VRow',[_c('VCol',[_c('VCard',[_c('VCardTitle',{staticClass:"justify-space-between"},[_c('span',[_vm._v("Просмотр архива "+_vm._s(_vm.$route.params.archiveId))]),_c('VBtn',{attrs:{"depressed":"","color":"primary","to":{ name : _vm.Names.R_ENGAGEMENT_REPORTS_ARCHIVES }}},[_c('VIcon',{attrs:{"left":""}},[_vm._v(" fal fa-chevron-left ")]),_vm._v(" Назад к архивам ")],1)],1),_c('VCardText',[(true)?_c('VCard',{staticClass:"mb-4"},[_c('VCardText',[_c('VExpansionPanels',{attrs:{"accordion":"","flat":""}},[_c('VExpansionPanel',[_c('VExpansionPanelHeader',[_vm._v("Информация")]),_c('VExpansionPanelContent',[_c('div',{staticClass:"ma-4"},_vm._l((Object.keys(_vm.computedArchive)),function(el){return _c('div',{key:((_vm.computedArchive.id) + "_" + el),staticClass:"d-flex"},[_c('div',{staticClass:"mr-1 font-weight-bold"},[_vm._v(" "+_vm._s(el)+": ")]),_c('div',{staticClass:"d-flex align-center"},[(el !== 'archiveUrl')?_c('div',[_vm._v(" "+_vm._s(_vm.computedArchive[el])+" ")]):_vm._e(),(el === 'companyId')?_c('TTBtn',{staticClass:"mt-n1",attrs:{"icon":"","x-small":"","title":"Открыть компанию в новой вкладке"},on:{"click":function($event){return _vm.goToCompany(_vm.computedArchive.companyId)}}},[_c('VIcon',{staticClass:"tt-blue--text text-center",attrs:{"size":"12"}},[_vm._v(" $externalLink ")])],1):_vm._e(),(el === 'userId')?_c('RouterLink',{staticClass:"d-inline-flex ml-2 mt-n1 tt-light-blue--text",attrs:{"to":{
                            name : _vm.Names.R_AUTHENTICATION_USER,
                            params : { userId : _vm.computedArchive.userId }
                          },"target":"_blank","title":"Открыть пользователя в новой вкладке"}},[_c('VIcon',{staticClass:"tt-blue--text valign-middle",attrs:{"size":"12"}},[_vm._v(" $externalLink ")])],1):_vm._e(),(el === 'archiveUrl')?_c('a',{attrs:{"href":_vm.computedArchive.archiveUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.computedArchive.archiveUrl)+" ")]):_vm._e()],1)])}),0)])],1)],1)],1)],1):_vm._e(),_c('ArchivesReportsTable',{attrs:{"reports":_vm.reports,"loading":_vm.loading},scopedSlots:_vm._u([{key:"rowActions",fn:function(ref){
                          var item = ref.item;
return [_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){return _vm.restartReport(item.id)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-sync ")])],1)]}}],null,true)},[_c('span',[_vm._v("Рестарт")])])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }