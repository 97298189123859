<template>
  <VRow>
    <VCol>
      <VCard>
        <VCardTitle class="justify-space-between">
          <span>Просмотр архива {{ $route.params.archiveId }}</span>
          <VBtn
            depressed
            color="primary"
            :to="{ name : Names.R_ENGAGEMENT_REPORTS_ARCHIVES }"
          >
            <VIcon left>
              fal fa-chevron-left
            </VIcon>
            Назад к архивам
          </VBtn>
        </VCardTitle>
        <VCardText>
          <VCard
            v-if="true"
            class="mb-4"
          >
            <VCardText>
              <VExpansionPanels
                accordion
                flat
              >
                <VExpansionPanel>
                  <VExpansionPanelHeader>Информация</VExpansionPanelHeader>
                  <VExpansionPanelContent>
                    <div class="ma-4">
                      <div
                        v-for="el in Object.keys(computedArchive)"
                        :key="`${computedArchive.id}_${el}`"
                        class="d-flex"
                      >
                        <div
                          class="mr-1 font-weight-bold"
                        >
                          {{ el }}:
                        </div>
                        <div class="d-flex align-center">
                          <div
                            v-if="el !== 'archiveUrl'"
                          >
                            {{ computedArchive[el] }}
                          </div>
                          <TTBtn
                            v-if="el === 'companyId'"
                            icon
                            x-small
                            class="mt-n1"
                            title="Открыть компанию в новой вкладке"
                            @click="goToCompany(computedArchive.companyId)"
                          >
                            <VIcon
                              size="12"
                              class="tt-blue--text text-center"
                            >
                              $externalLink
                            </VIcon>
                          </TTBtn>

                          <RouterLink
                            v-if="el === 'userId'"
                            class="d-inline-flex ml-2 mt-n1 tt-light-blue--text"
                            :to="{
                              name : Names.R_AUTHENTICATION_USER,
                              params : { userId : computedArchive.userId }
                            }"
                            target="_blank"
                            title="Открыть пользователя в новой вкладке"
                          >
                            <VIcon
                              size="12"
                              class="tt-blue--text valign-middle"
                            >
                              $externalLink
                            </VIcon>
                          </RouterLink>
                          <a
                            v-if="el === 'archiveUrl'"
                            :href="computedArchive.archiveUrl"
                            target="_blank"
                          >
                            {{ computedArchive.archiveUrl }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </VExpansionPanelContent>
                </VExpansionPanel>
              </VExpansionPanels>
            </VCardText>
          </VCard>
          <ArchivesReportsTable
            :reports="reports"
            :loading="loading"
          >
            <template #rowActions="{ item }">
              <VTooltip left>
                <template #activator="{ on, attrs }">
                  <VBtn
                    icon
                    small
                    color="red"
                    class="mx-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="restartReport(item.id)"
                  >
                    <VIcon small>
                      fal fa-sync
                    </VIcon>
                  </VBtn>
                </template>

                <span>Рестарт</span>
              </VTooltip>
            </template>
          </ArchivesReportsTable>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<script>
import ArchivesReportsTable from '../../components/reports/ArchivesReportsTable.vue';

export default {
  name: 'ArchiveView',

  components: {
    ArchivesReportsTable,
  },

  inject: ['Names'],

  data() {
    return {
      loading: false,
      reports: [],
      archive: null,
    };
  },

  computed: {
    computedArchive() {
      if (!this.archive) return {};
      return {
        id: this.archive.id,
        name: this.archive.name,
        status: this.archive.status,
        email: this.archive.email,
        surveyId: this.archive.surveyId,
        userId: this.archive.userId,
        companyId: this.archive.cookies[0].value,
        createdAt: this.$dayjs.utc(this.archive.createdAt).format('DD.MM.YYYY HH:mm:ss'),
        updatedAt: this.$dayjs.utc(this.archive.updatedAt).format('DD.MM.YYYY HH:mm:ss'),
        archiveUrl: this.archive.archiveUrl,
      };
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async goToCompany(id) {
      try {
        const { company } = await this.$di.api.Account.getCompany({ id });
        const url = this.$router.resolve({
          name: this.Names.R_ACCOUNT_V2_COMPANY_VIEW,
          params: {
            accountId: company.accountId,
            companyId: company.id,
          },
        }).href;
        window.open(url, '_blank');
      } catch (err) {
        this.$di.notify.errorHandler(err);
      }
    },
    fetch() {
      this.loading = true;

      this.$di.api.ReportServiceApi
        .reportsArchiveReportsGet(this.$route.params.archiveId)
        .then(({ archive, reports }) => {
          this.archive = archive;
          this.reports = reports;
        })
        .catch(this.$di.notify.errorHandler)
        .finally(() => {
          this.loading = false;
        });
    },
    async restartReport(id) {
      try {
        this.loading = true;
        await this.$di.api.ReportServiceApi.reportsArchiveReportsReportRestartPut(id);
        await this.$nextTick();
        await this.fetch();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
