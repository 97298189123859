var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VCard',[_c('VCardTitle',[_c('VRow',{attrs:{"align":"center"}},[_c('VCol',{attrs:{"cols":"6"}},[_c('TTTextField',{staticClass:"tt-text-body-2",attrs:{"placeholder":"Поиск"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('VIcon',[_vm._v(" fal fa-search ")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('VRow',[_c('VCol',[_c('TTChipGroup',{staticClass:"ma-4 mt-0",attrs:{"multiple":""},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}},_vm._l((_vm.statuses),function(status,i){return _c('TTChip',{key:i,attrs:{"value":status}},[_vm._v(" "+_vm._s(status)+" ")])}),1)],1)],1),_c('VDataTable',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.computedReports,"loading":_vm.loading,"expanded":_vm.expanded,"search":_vm.search,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('VToolbar',{attrs:{"flat":""}},[_c('VToolbarTitle',[_vm._v("Архивные отчеты пользователей")]),_c('VSpacer'),_vm._t("topActions")],2)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_vm._t("rowActions",null,{"item":item})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('VChip',_vm._b({attrs:{"small":"","outlined":false}},'VChip',_vm.chipStyle(item.status),false),[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"ma-4"},_vm._l((Object.keys(item)),function(el){return _c('div',{key:((item.id) + "_" + el),staticClass:"d-flex"},[_c('div',{staticClass:"mr-1 font-weight-bold"},[_vm._v(" "+_vm._s(el)+": ")]),_c('div',{staticClass:"d-flex align-center"},[(el !== 'archiveUrl')?_c('div',[_vm._v(" "+_vm._s(item[el])+" ")]):_vm._e(),(el === 'companyId')?_c('TTBtn',{staticClass:"mt-n1",attrs:{"icon":"","x-small":"","title":"Открыть компанию в новой вкладке"},on:{"click":function($event){return _vm.goToCompany(item.companyId)}}},[_c('VIcon',{staticClass:"tt-blue--text text-center",attrs:{"size":"12"}},[_vm._v(" $externalLink ")])],1):_vm._e(),(el === 'userId')?_c('RouterLink',{staticClass:"d-inline-flex ml-2 mt-n1 tt-light-blue--text",attrs:{"to":{
                  name : _vm.Names.R_AUTHENTICATION_USER,
                  params : { userId : item.userId }
                },"target":"_blank","title":"Открыть пользователя в новой вкладке"}},[_c('VIcon',{staticClass:"tt-blue--text valign-middle",attrs:{"size":"12"}},[_vm._v(" $externalLink ")])],1):_vm._e(),(el === 'archiveUrl')?_c('a',{attrs:{"href":item.archiveUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(item.archiveUrl)+" ")]):_vm._e()],1)])}),0)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }